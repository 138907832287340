import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function SalesforceBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform container__heading--no-logo">
              Salesforce Experience Cloud{" "}
            </h1>
            <h2 className="container__sub-heading container__sub-heading--platform">
              Deliver contextual, just in time, customer-first digital
              experiences on the world's #1 CRM
            </h2>
            <h6 className="container__content">
              Hashout is a Salesforce partner with experience in strategizing,
              designing and building award-winning ROI focused marketing
              experiences, Self-services focused post-purchase experiences and
              communities for enterprise in the Technology, Healthcare, and
              Logistics space.
            </h6>
            <button
              className="container__button container__button--platform"
              onClick={openModal}
            >
              Get 40 hrs free consultation
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/salesforce-experience-cloud.png"
              alt="Salesforce Experience Cloud"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Salesforce-Banner"
        page="salesforce-experience-cloud"
       
      />
    </section>
  );
}

export default SalesforceBanner;
