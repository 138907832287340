import React from "react";

function SalesforceBlog() {
  return (
    <section className="section section--blog section--blog-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center">
          <div className="container__col-md-6 --section-large-tb-padding">
            <p className="container__sub-heading">BLOG</p>
            <h2 className="container__heading">
              Hashout guide to connecting Salesforce Knowledge and CMS for
              self-service
            </h2>
            <p className="container__content --italic">
              “Leverage your AEM Digital Experience to drive customer self
              service”
            </p>
            <p className="container__content"></p>
            <a
              className="--action-link"
              href="../../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
              target="_blank"
            >
              Read Whitepaper
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              src="../../solutions/platforms/salesforce-experience-cloud.png"
              alt="connecting Salesforce Knowledge and CMS"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default SalesforceBlog;
