import React from "react";

function StraumannCaseStudy() {
  return (
    <section className="section section--blog section--tools-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center">
          <div className="container__col-md-6 --section-large-tb-padding">
            <p className="container__sub-heading">CASE STUDY</p>
            <h2 className="container__heading">
              Straumann - How Hashout Tech Helped a Leading Dentistry Business
              Get the Best Out of AEM
            </h2>
            <p className="container__content container__content--red">
              200% more leads generated &<br /> $3m in Product Sales
            </p>
            <a
              className="container__link --action-link --download-icon"
              href="../../Turn-campaign-insights-in-to-qualified-leads-Straumann-Casestudy.pdf"
              target="_blank"
            >
              download case study
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="--bg-absolute-right"
              src="../../solutions/platforms/straumann.png"
              alt="straumann"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default StraumannCaseStudy;
