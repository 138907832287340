import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import OurProjects from "../components/Solutions/OurProjects_MobileSolutions/OurProjects";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import SalesforceBanner from "../components/Platforms/SalesforceBanner/SalesforceBanner";
import StraumannCaseStudy from "../components/Platforms/StraumannCaseStudy/StraumannCaseStudy";
import SalesforcBlog from "../components/Platforms/SalesforceBlog/SalesforceBlog";
import SalesforcVideo from "../components/Platforms/SalesforceVideo/SalesforceVideo";
import Sites from "../components/Platforms/SalesforceSites/SalesforceSites";
import { Helmet } from "react-helmet";
function SalesforceExperienceCloud() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies -    Salesforce Experience Cloud     </title>
       <meta
         name="description"
         content="
         Deliver contextual, just in time, customer-first digital experiences on the world's #1 CRM."
       ></meta></Helmet>
      <SolutionsHelmet />
      <SalesforceBanner />
      <StraumannCaseStudy />
      <Sites />
      <OurProjects />
      <SalesforcVideo />
      <SalesforcBlog />
      <OurPerspectives />
      <LearnAboutUs page="salesforce-experience-cloud"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default SalesforceExperienceCloud;
